import React from 'react';
import './smart.css';
import secureImage from './images/secure.png';
import transparentImage from './images/transparent.png';
import cashoutImage from './images/cashout.png';
import reinvestImage from './images/reinvest.png';

const Smart = () => {
  return (
    <div className="smart">
      <div className="smart-section">
        <h3 className="smart-title">Smarter Property Investing</h3>
        <div className="smart-images">
          <div className="smart-image">
            <img src={secureImage} alt="Secure Investment" />
            <p className="smart-image-title">Secure Investment</p>
          </div>
          <div className="smart-image">
            <img src={transparentImage} alt="Transparent Platform" />
            <p className="smart-image-title">Transparent Platform</p>
          </div>
          <div className="smart-image">
            <img src={cashoutImage} alt="Cash Out Any Time" />
            <p className="smart-image-title">Cash Out Any Time</p>
          </div>
          <div className="smart-image">
            <img src={reinvestImage} alt="Reinvest" />
            <p className="smart-image-title">Reinvest</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smart;
