import React, { useState, useEffect } from 'react';
import './Home.css';
import Banner from './pages/banner.jsx';
import Smart from './pages/smart';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div className="home">
      <Banner />
      <div className="smart-section">
        <Smart />
      </div>
      <h1 className={windowWidth > 768 ? 'home-title' : 'home-title small'}>
        Discover the Infinite Possibilities of Tokenization
      </h1>
      <div className="home-image-container">
        <img
          className="home-image"
          src="https://lenderkit.com/wp-content/uploads/webp/2022/11/fractional-ownership-1100x563-png.webp"
          alt="Real Estate"
        />
      </div>
      <div
        className={windowWidth > 768 ? 'home-content' : 'home-content small'}
      >
        <p>
          Unlocking the world of real estate investment has never been easier or
          more exciting. Imagine owning a piece of your dream property,
          enjoying its benefits, and even generating passive income from it.
          With fractional ownership, this vision becomes a reality, and we're
          here to show you just how incredible it can be.
        </p>
        {/* Rest of the content */}
      </div>
    </div>
  );
};

export default Home;
