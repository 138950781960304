import React, { useState, useEffect } from 'react';
import NFTCollection from './NFTCollection';
import { getOwnedNFTCollection, getRentedNFTCollection, getRentedOutNFTCollection } from './NFTService';
import './Dashboard.css';

const contractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8';

const Dashboard = () => {
  const [ownedNFTCollection, setOwnedNFTCollection] = useState([]);
  const [rentedNFTCollection, setRentedNFTCollection] = useState([]);
  const [rentedOutNFTCollection, setRentedOutNFTCollection] = useState([]);

  useEffect(() => {
    fetchNFTCollections();
  }, []);

  const fetchNFTCollections = async () => {
    const ownedNFTs = await getOwnedNFTCollection(contractAddress);
    const rentedNFTs = await getRentedNFTCollection(contractAddress);
    const rentedOutNFTs = await getRentedOutNFTCollection(contractAddress);

    setOwnedNFTCollection(ownedNFTs);
    setRentedNFTCollection(rentedNFTs);
    setRentedOutNFTCollection(rentedOutNFTs);
  };

  return (
    <div className="dashboard">
      <NFTCollection title="Owned NFTs" collection={ownedNFTCollection} contractAddress={contractAddress} showEarnings />
      <hr className="nft-divider" />
      <NFTCollection title="Rented NFTs" collection={rentedNFTCollection} contractAddress={contractAddress} showEarnings />
      <hr className="nft-divider" />
      <NFTCollection title="Rented Out NFTs" collection={rentedOutNFTCollection} contractAddress={contractAddress} />
    </div>
  );
};

export default Dashboard;
