import React from 'react';
import './Banner.css';
import bannerImage from './images/hero-two-illustration.png';

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <h1 className="banner-title">
          <span className="banner-title-highlight-dark">Smart.</span> <span className="banner-title-highlight-light">Simple. Accessible.</span>
        </h1>
        <h4 className="banner-subtitle">
          Investing Made Simple &amp; Transparent
        </h4>
        <p className="banner-description">
          Start growing your passive income today. Invest from as low as 100 EUR in real estate opportunities worldwide.
        </p>
        <h3 className="explore-button">Start Exploring</h3>
      </div>
      <div className="banner-image-container">
        <img
          className="banner-image"
          src={bannerImage}
          alt="Visualization"
        />
      </div>
    </div>
  );
};

export default Banner;
