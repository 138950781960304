import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import contractABI from './abi.json';
import { useParams, useNavigate } from 'react-router-dom';
import './NFTDetails.css';
import { sellNFT } from './sellnft';
import _setRentalAvailability from './setRentalAvailability';
import breakRental from './endRental';
import ReactPlayer from 'react-player';

const NFTDetails = () => {
  const { contractAddress, tokenId } = useParams();
  const navigate = useNavigate();

  const [nftDetails, setNFTDetails] = useState(null);
  const [traits, setTraits] = useState([]);
  const [sellPrice, setSellPrice] = useState('0');
  const [rentalPrice, setRentalPrice] = useState('0');
  const [showSellInput, setShowSellInput] = useState(false);
  const [showRentInput, setShowRentInput] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const marketplaceContractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8';

  useEffect(() => {
    fetchNFTDetails();
  }, []);

  useEffect(() => {
    checkOwnership();
  }, []);

  const fetchNFTDetails = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const tokenURI = await contract.methods.tokenURI(tokenId).call();
      const response = await fetch(tokenURI);
      const metadata = await response.json();

      setNFTDetails(metadata);
      setTraits(metadata.attributes);
    } catch (error) {
      console.error('Error fetching NFT details:', error);
    }
  };

  const checkOwnership = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const owner = await contract.methods.ownerOf(tokenId).call();
      setIsOwner(owner.toLowerCase() === accounts[0].toLowerCase());
    } catch (error) {
      console.error('Error checking ownership:', error);
    }
  };

  const handleSell = () => {
    setShowSellInput(true);
    setShowRentInput(false);
    setSellPrice('0');
    setRentalPrice('0');
  };

  const handleRent = () => {
    setShowRentInput(true);
    setShowSellInput(false);
    setSellPrice('0');
    setRentalPrice('0');
  };

  const handleCancel = () => {
    setShowSellInput(false);
    setShowRentInput(false);
    setSellPrice('0');
    setRentalPrice('0');
  };

  const handleConfirmSell = async () => {
    if (!sellPrice) {
      alert('Please provide a sell price');
      return;
    }

    setStatusMessage('Approving NFT...');

    try {
      const web3 = new Web3(window.ethereum);
      const priceInWei = web3.utils.toWei(sellPrice, 'ether');
      const priceInMatic = web3.utils.fromWei(priceInWei, 'ether');

      const contract = new web3.eth.Contract(contractABI, contractAddress);
      await contract.methods
        .approve(marketplaceContractAddress, tokenId)
        .send({ from: web3.currentProvider.selectedAddress });

      setStatusMessage('Setting NFT up for sale...');

      await sellNFT(contractAddress, tokenId, priceInMatic);

      setStatusMessage('NFT has been set up for sale successfully!');

      setTimeout(() => {
        setStatusMessage('');
        navigate('/dashboard');
      }, 1000);
    } catch (error) {
      console.error('Error setting up NFT for sale:', error);
      setStatusMessage('Failed to set up NFT for sale.');
    }
  };

  const handleConfirmRent = async () => {
    if (!rentalPrice) {
      alert('Please provide a rental price');
      return;
    }

    setStatusMessage('Approving NFT...');

    try {
      const web3 = new Web3(window.ethereum);
      const priceInWei = web3.utils.toWei(rentalPrice, 'ether');

      const contract = new web3.eth.Contract(contractABI, contractAddress);
      await contract.methods
        .approve(marketplaceContractAddress, tokenId)
        .send({ from: web3.currentProvider.selectedAddress });

      setStatusMessage('Setting NFT availability for rental...');

      await _setRentalAvailability(contractAddress, tokenId, true, priceInWei);

      setStatusMessage('NFT has been set up for rent successfully!');

      setTimeout(() => {
        setStatusMessage('');
        navigate('/dashboard');
      }, 1000);
    } catch (error) {
      console.error('Error setting up NFT for rent:', error);
      setStatusMessage('Failed to set up NFT for rent.');
    }
  };

  const handleEndRent = async () => {
    await breakRental(contractAddress, tokenId);
  };

  if (!nftDetails) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="nft-details-container">
      <div className="status-message-container">
        <p>{statusMessage}</p>
      </div>
      <div className="nft-media-container">
        {traits.map((trait, index) => {
          if (trait.trait_type === 'Video Link') {
            return (
              <div className="nft-video-container" key={index}>
                <ReactPlayer url={trait.value} controls={true} width="100%" height="100%" />
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="nft-info-container">
        <h1 className="nft-name">{nftDetails.name}</h1>
        <p className="nft-description">{nftDetails.description}</p>
        <div className="nft-traits-container">
          <h2 className="nft-traits-heading">Traits:</h2>
          <ul className="nft-traits-list">
            {traits.map((trait, index) => {
              if (trait.trait_type !== 'Video Link') {
                return (
                  <li className="nft-trait-item" key={index}>
                    <span className="trait-label">{trait.trait_type}:</span> {trait.value}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
      <div className="nft-button-container">
        {isOwner && (
          <>
            {showSellInput || showRentInput ? (
              <>
                <button className="nft-button nft-button-confirm" onClick={showSellInput ? handleConfirmSell : handleConfirmRent}>
                  Confirm
                </button>
                <button className="nft-button nft-button-cancel" onClick={handleCancel}>
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button className="nft-button nft-button-sell" onClick={handleSell}>
                  Sell NFT
                </button>
                <button className="nft-button nft-button-rent" onClick={handleRent}>
                  Rent NFT
                </button>
              </>
            )}
          </>
        )}
        {!isOwner && (
          <button className="nft-button nft-button-end-rent" onClick={handleEndRent}>
            End Rent
          </button>
        )}
        {showSellInput && (
          <div className="nft-input-fields">
            <label htmlFor="sellPrice" className="nft-input-label">
              Price (in ETH):
            </label>
            <input
              type="number"
              id="sellPrice"
              name="sellPrice"
              step="0.00000001"
              min="0"
              value={sellPrice}
              onChange={(e) => setSellPrice(e.target.value)}
              className="nft-input"
            />
          </div>
        )}
        {showRentInput && (
          <div className="nft-input-fields">
            <label htmlFor="rentalPrice" className="nft-input-label">
              Rental Price (in ETH):
            </label>
            <input
              type="number"
              id="rentalPrice"
              name="rentalPrice"
              step="0.00000001"
              min="0"
              value={rentalPrice}
              onChange={(e) => setRentalPrice(e.target.value)}
              className="nft-input"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTDetails;
