import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { pinFileToIPFS, pinJSONToIPFS } from './pinata';
import abi from './abi.json';
import './mintnft.css'; // Import the CSS file

const CONTRACT_ADDRESS = '0x036D7F28e6D626521228682Cd701ffA0b16E100e'; // Define the contract address here

const Mintnft = () => {
  const [account, setAccount] = useState('');
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [contractOwner, setContractOwner] = useState('');
  const [showWhitelistButtons, setShowWhitelistButtons] = useState(false);
  const [whitelistAddressInput, setWhitelistAddressInput] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('Fractional Ownership NFT, made by RealtyToke.com');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [squareFootage, setSquareFootage] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [propertyDescription, setPropertyDescription] = useState('');
  const [amenities, setAmenities] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [contractDetail, setContractDetail] = useState('');
  const [virtualTour, setVirtualTour] = useState('');
  const [image, setImage] = useState(null);
  const [pinningProgress, setPinningProgress] = useState(0);
  const [mintingProgress, setMintingProgress] = useState(0);

  useEffect(() => {
    const initializeWeb3 = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
          setIsConnected(true);
        } catch (error) {
          console.error('Error connecting to Web3:', error);
        }
      } else {
        console.error('Web3 not found');
      }
    };

    initializeWeb3();
  }, []);

  useEffect(() => {
    const fetchContractOwner = async () => {
      if (!web3) return;
      
      const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESS);
      const owner = await contract.methods.owner().call();
      setContractOwner(owner);
    };

    fetchContractOwner();
  }, [web3]);

  useEffect(() => {
    setShowWhitelistButtons(contractOwner === account);
  }, [contractOwner, account]);

  const whitelistAddress = async () => {
    try {
      const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESS);
      await contract.methods.addToWhitelist(whitelistAddressInput).send({ from: account });
      setSuccessMessage('Address whitelisted successfully!');
    } catch (error) {
      setError('Error whitelisting address: ' + error.message);
    }
  };

  const unwhitelistAddress = async () => {
    try {
      const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESS);
      await contract.methods.removeFromWhitelist(whitelistAddressInput).send({ from: account });
      setSuccessMessage('Address removed from whitelist successfully!');
    } catch (error) {
      setError('Error removing address from whitelist: ' + error.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handlePinningAndMinting = async () => {
    try {
      if (!isConnected || !web3 || !account) {
        throw new Error('Wallet is not connected. Please connect your wallet.');
      }

      // Check if the account is whitelisted
      const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESS);
      
      setSuccessMessage('Step 1: Pinning the image to IPFS...');
      const imageIpfsUrl = await pinFileToIPFS(image, setPinningProgress);
      console.log('Image pinned to IPFS:', imageIpfsUrl);
      setSuccessMessage('Image pinned to Pinata: ' + imageIpfsUrl);

      setSuccessMessage('Step 2: Creating the NFT JSON file...');
      const nftData = {
        name,
        description,
        price,
        address,
        city,
        country,
        bedrooms,
        bathrooms,
        squareFootage,
        yearBuilt,
        propertyDescription,
        amenities,
        contactInfo,
        videoLink,
        contractDetail,
        virtualTour,
        image: 'https://gateway.pinata.cloud/ipfs/' + imageIpfsUrl,
      };

      console.log('NFT Data:', nftData);

      const nftIpfsUrl = await pinJSONToIPFS(nftData, setPinningProgress);
      console.log('NFT JSON pinned to IPFS:', nftIpfsUrl);
      setSuccessMessage('NFT JSON pinned to Pinata: https://gateway.pinata.cloud/ipfs/' + nftIpfsUrl);

      setSuccessMessage('Step 3: Minting the NFT...');
      const isWhitelisted = await contract.methods.mintNFTWithURI(account, nftIpfsUrl).send({ from: account });

      if (!isWhitelisted) {
        throw new Error('You are not whitelisted to mint NFTs. Contact us to get whitelisted.');
      }

      const totalNFTs = 0; // Number of NFTs to mint
      const mintPromises = [];

      for (let i = 0; i < totalNFTs; i++) {
        mintPromises.push(
          contract.methods
            .mintNFTWithURI(account, 'https://gateway.pinata.cloud/ipfs/' + nftIpfsUrl)
            .send({ from: account })
        );
      }

      Promise.all(mintPromises)
        .then((results) => {
          results.forEach((hash) => {
            setSuccessMessage('Transaction submitted. Hash: ' + hash.transactionHash);
          });
          setSuccessMessage('NFTs minted successfully!');
          setSuccessMessage('Transaction ID: ' + results[0].receipt.transactionHash);
        })
        .catch(console.error);
    } catch (error) {
      console.error('Error pinning image or creating NFT:', error.message);
      setError('Error creating NFT: ' + error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !image) {
      setError('Please provide a name and choose an image to upload.');
      return;
    }
    handlePinningAndMinting();
  };

  return (
    <div className="mint-form-container">
      <section className="ask section__space bg__img">
        <div className="container">
          <div className="ask__area">
            <div className="alert__newsletter__area">
              <div className="section__header">
                <h4 className="neutral-top">Add your property</h4>
              </div>
              {/* ... (previously defined wallet and whitelist buttons) */}
              <form onSubmit={handleSubmit}>
                <div className="input input--secondary">
                  <label htmlFor="nftName">Name</label>
                  <input
                    type="text"
                    id="nftName"
                    name="nftName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftPrice">Price</label>
                  <input
                    type="text"
                    id="nftPrice"
                    name="nftPrice"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftDescription">Description</label>
                  <textarea
                    id="nftDescription"
                    name="nftDescription"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftAddress">Address</label>
                  <input
                    type="text"
                    id="nftAddress"
                    name="nftAddress"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftCity">City</label>
                  <input
                    type="text"
                    id="nftCity"
                    name="nftCity"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftCountry">Country</label>
                  <input
                    type="text"
                    id="nftCountry"
                    name="nftCountry"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftBedrooms">Bedrooms</label>
                  <input
                    type="number"
                    id="nftBedrooms"
                    name="nftBedrooms"
                    value={bedrooms}
                    onChange={(e) => setBedrooms(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftBathrooms">Bathrooms</label>
                  <input
                    type="number"
                    id="nftBathrooms"
                    name="nftBathrooms"
                    value={bathrooms}
                    onChange={(e) => setBathrooms(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftSquareFootage">Square Footage</label>
                  <input
                    type="number"
                    id="nftSquareFootage"
                    name="nftSquareFootage"
                    value={squareFootage}
                    onChange={(e) => setSquareFootage(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftYearBuilt">Year Built</label>
                  <input
                    type="number"
                    id="nftYearBuilt"
                    name="nftYearBuilt"
                    value={yearBuilt}
                    onChange={(e) => setYearBuilt(e.target.value)}
                  />
                </div>
                {/* Add more input fields for other properties */}
                {/* For example: */}
                <div className="input input--secondary">
                  <label htmlFor="nftPropertyDescription">Property Description</label>
                  <textarea
                    id="nftPropertyDescription"
                    name="nftPropertyDescription"
                    value={propertyDescription}
                    onChange={(e) => setPropertyDescription(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftAmenities">Amenities</label>
                  <textarea
                    id="nftAmenities"
                    name="nftAmenities"
                    value={amenities}
                    onChange={(e) => setAmenities(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftContactInfo">Contact Info</label>
                  <input
                    type="text"
                    id="nftContactInfo"
                    name="nftContactInfo"
                    value={contactInfo}
                    onChange={(e) => setContactInfo(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftVideoLink">Video Link</label>
                  <input
                    type="text"
                    id="nftVideoLink"
                    name="nftVideoLink"
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftContractDetail">Contract Detail</label>
                  <textarea
                    id="nftContractDetail"
                    name="nftContractDetail"
                    value={contractDetail}
                    onChange={(e) => setContractDetail(e.target.value)}
                  />
                </div>
                <div className="input input--secondary">
                  <label htmlFor="nftVirtualTour">Virtual Tour</label>
                  <input
                    type="text"
                    id="nftVirtualTour"
                    name="nftVirtualTour"
                    value={virtualTour}
                    onChange={(e) => setVirtualTour(e.target.value)}
                  />
                </div>
                {/* Add more input fields for other properties */}
                {/* ... */}
                <div className="input input--secondary">
                  <label htmlFor="nftImage">Image</label>
                  <input
                    type="file"
                    id="nftImage"
                    name="nftImage"
                    onChange={handleFileChange}
                  />
                  {image && (
                    <div className="image-preview" style={{ maxWidth: '100%', maxHeight: '100%' }}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Selected Image"
                        width={300}
                        height={300}
                      />
                    </div>
                  )}
                </div>
                <button type="submit" className="button button--large button--primary">
                  Mint NFTs
                </button>
              </form>
              {error && <p className="error">{error}</p>}
              {successMessage && <p className="success">{successMessage}</p>}
              {(pinningProgress > 0 || mintingProgress > 0) && (
                <p>Pinning and minting progress: {pinningProgress}%</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mintnft;