import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import abi from './abi.json';

const KYCList = () => {
  const [kycList, setKYCList] = useState([]);
  const [signerAddress, setSignerAddress] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchKYCs = async () => {
      try {
        const web3 = new Web3(window.ethereum);
        const contractAddress = '0x7857B8A6aB7B0C428503EF78eb97577bC5Fb9275';
        const contract = new web3.eth.Contract(abi, contractAddress);

        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          const signer = accounts[0];
          setSignerAddress(signer);
          console.log('Setting Signer Address:', signer);

          const kycs = [];
          let i = 1;
          while (true) {
            try {
              const owner = await contract.methods.ownerOf(i).call();
              const tokenURI = await contract.methods.tokenURI(i).call();

              const tokenData = await fetchTokenData(contract, i, signer);
              if (tokenData) {
                const kyc = { tokenId: i, owner, tokenURI, ...tokenData };
                kycs.push(kyc);
              }

              console.log('Fetched KYC:', { tokenId: i, owner, tokenURI });
              i++;
            } catch (error) {
              console.log('Error fetching KYC at token ID:', i, error);
              // Break the loop when a token does not exist
              break;
            }
          }

          console.log('Fetched KYCs:', kycs);
          setKYCList(kycs);
        } else {
          setSignerAddress('');
          console.log('Signer Address not available');
        }
      } catch (error) {
        console.log('Error fetching KYCs:', error);
        setError('Error fetching KYCs');
      }
    };

    fetchKYCs();
  }, []);

  const fetchTokenData = async (contract, tokenId, signerAddress) => {
    try {
      console.log('fetchTokenData - Contract:', contract);
      console.log('fetchTokenData - Token ID:', tokenId);
      console.log('fetchTokenData - Signer Address:', signerAddress);

      const response = await contract.methods.getKYCFields(tokenId).call({ from: signerAddress });
      const tokenData = response || {};

      console.log('Token ID:', tokenId);
      console.log('Owner:', signerAddress);
      console.log('Token URI:', tokenData.tokenURI);

      const addurl = tokenData.traits;
      console.log('addurl:', addurl);

      return {
        name: tokenData.name,
        email: tokenData.email,
        imageUrl: tokenData.imageUrl,
        traits: tokenData.traits,
        ...tokenData
      };
    } catch (error) {
      console.log('Error fetching KYC fields for token ID:', tokenId, error);
      setError(`Error fetching KYC fields for token ID: ${tokenId}`);
      return null;
    }
  };

  return (
    <div>
      <h2>KYC List</h2>
      {error && <p>Error: {error}</p>}
      {signerAddress && <p>Signer Address: {signerAddress}</p>}
      {!signerAddress ? (
        <p>Please connect your wallet to view KYCs</p>
      ) : kycList.length === 0 ? (
        <p>Loading KYCs...</p>
      ) : (
        <ul>
          {kycList.map((kyc) => (
            <li key={kyc.tokenId}>
              <p>Token ID: {kyc.tokenId}</p>
              <p>Owner: {kyc.owner}</p>
              <p>Token URI: {kyc.tokenURI}</p>
              <p>Name: {kyc.name}</p>
              <p>Email: {kyc.email}</p>
              <button>
                <a href={'https://gateway.pinata.cloud/ipfs/' + kyc.traits} target="_blank" rel="noopener noreferrer">
                  Traits
                </a>
              </button> {/* Display a button with a clickable URL to traits */}
              {Object.entries(kyc).map(([key, value]) => (
                <p key={key}>
                  {key}: {value}
                </p>
              ))}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default KYCList;
