import Web3 from 'web3';
import contractABI from './abi.json';

export const sellNFT = async (contractAddress, tokenId, price) => {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const accounts = await web3.eth.getAccounts();
    const currentAccount = accounts[0];

    if (!currentAccount) {
      return { success: false, message: 'Please connect your wallet' };
    }

    const priceInWei = web3.utils.toWei(price, 'ether');

    await contract.methods.setSaleAvailabilityAndPrice(tokenId, true, priceInWei).send({
      from: currentAccount,
    });

    return { success: true, message: 'NFT set for sale successfully' };
  } catch (error) {
    console.error('Error setting NFT for sale:', error);
    return { success: false, message: 'Error setting NFT for sale. Please check the console for details.' };
  }
};
