import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <header className="roadmap-header">
        <h1>Roadmap for RealtyToke Project</h1>
      </header>
      <div className="roadmap-content">
        <div className="quarter">
          <h2>Q1 2023</h2>
          <ul>
            <li>Initiate the project and assemble a highly skilled and experienced development team to ensure the successful execution of the RealtyToke platform.</li>
            <li>Conduct extensive market research and in-depth analysis of fractional ownership models within the real estate industry, identifying key challenges and opportunities.</li>
            <li>Develop a sophisticated and robust smart contract infrastructure that provides a secure and efficient mechanism for fractional ownership of properties.</li>
            <li>Rigorously test and optimize the smart contract, employing industry-leading security protocols and conducting comprehensive audits to guarantee the integrity of the platform.</li>
            <li>Establish a dedicated test environment to meticulously evaluate the functionality and reliability of the smart contract, addressing any potential vulnerabilities or performance bottlenecks.</li>
          </ul>
        </div>
        <div className="quarter">
          <h2>Q2 2023</h2>
          <ul>
            <li>Embark on an ambitious design and development phase, crafting an elegant and captivating front-end interface that seamlessly connects users with the RealtyToke platform.</li>
            <li>Employ cutting-edge UI/UX principles to create a visually stunning and intuitively navigable interface, ensuring an exceptional user experience throughout the buying, selling, and renting processes.</li>
            <li>Integrate secure payment gateways, leveraging the latest encryption technologies to safeguard financial transactions and instill trust among users.</li>
            <li>Conduct rigorous testing of the front-end interface, employing user-centric methodologies and iterative feedback loops to refine and optimize usability, responsiveness, and overall performance.</li>
            <li>Prepare the platform for deployment, meticulously addressing all technical requirements and dependencies to guarantee a smooth and seamless launch.</li>
          </ul>
        </div>
        <div className="quarter">
          <h2>Q3 2023</h2>
          <ul>
            <li>Initiate an extensive marketing campaign to generate widespread awareness and excitement about the revolutionary RealtyToke platform.</li>
            <li>Establish a compelling online presence, including a meticulously designed and informative website, engaging social media accounts, and a robust customer support system to foster positive user interactions.</li>
            <li>Forge strategic partnerships with influential industry players, cultivating investor interest and attracting key stakeholders who recognize the immense potential of fractional ownership and real estate crowdfunding.</li>
            <li>Launch captivating crowdfunding initiatives, leveraging compelling narratives and persuasive value propositions to secure the necessary funds for legal support and continuous platform development.</li>
            <li>Pursue continuous enhancement of the website's functionality, performance, and aesthetics, ensuring an immersive and captivating user experience.</li>
            <li>Finalize all legal requirements and establish a highly skilled legal team to navigate the complex landscape of real estate regulations, guaranteeing full compliance and mitigating any potential legal risks.</li>
            <li>Implement a KYC (Know Your Customer) NFT solution to streamline the verification process and enhance user trust and security.</li>
            <li>Develop a KYC smart contract that simplifies the KYC process and securely stores the data on the blockchain, ensuring limited visibility only to authorized parties.</li>
            <li>Integrate the KYC NFT functionality into the RealtyToke platform, allowing users to submit their KYC information and receive a verifiable NFT as proof of completion.</li>
            <li>Enhance the platform's security measures to safeguard sensitive user data and protect the privacy of individuals.</li>
            <li>Collaborate with legal and compliance experts to ensure the KYC process adheres to the necessary legal frameworks and industry standards.</li>
            <li>Educate users about the benefits and importance of the KYC NFT, promoting transparency, trust, and compliance within the RealtyToke ecosystem.</li>
          </ul>
        </div>
        <div className="quarter">
          <h2>Q4 2023</h2>
          <ul>
            <li>Enrich the RealtyToke platform by seamlessly integrating an expanded selection of properties, offering prospective buyers a diverse range of fractional ownership opportunities.</li>
            <li>Implement an advanced portfolio management system, empowering users to effortlessly add properties to their portfolios and efficiently manage their fractional ownership holdings.</li>
            <li>Enable property owners to capitalize on their investments by providing comprehensive rental management features, simplifying the process of renting out fractional ownership units.</li>
            <li>Continuously refine and optimize the platform based on valuable user feedback and market demands, enhancing its user-friendliness, efficiency, and overall performance.</li>
            <li>Conduct extensive beta testing, meticulously gathering data and insights to further improve and fine-tune the platform's functionalities and ensure a flawless user experience.</li>
          </ul>
        </div>
        <div className="year">
          <h2>2024</h2>
          <ul>
            <li>Expand the boundaries of the RealtyToke platform by venturing beyond the realm of real estate, tokenizing an array of diverse assets, including art, collectibles, and intellectual property, to provide users with a broader spectrum of investment opportunities.</li>
            <li>Introduce the native token, RealtyToke, as the primary medium of exchange within the platform, facilitating seamless and secure transactions while nurturing an ecosystem of value creation and token appreciation.</li>
            <li>Integrate multiple payment options, encompassing both RealtyToke and traditional currencies, to maximize accessibility and accommodate a wide range of user preferences and requirements.</li>
            <li>Enhance the mobile application, ensuring full functionality and optimal user experience across various devices, empowering users to engage with the RealtyToke platform anytime, anywhere.</li>
            <li>Foster strategic alliances with renowned real estate agencies and industry partners, expanding the platform's property listings and augmenting the investment potential available to users.</li>
            <li>Continuously innovate and refine the smart contract infrastructure, prioritizing security, scalability, and efficiency, to accommodate the growing demands of the RealtyToke ecosystem.</li>
          </ul>
        </div>
        <div className="long-term">
          <h2>Long-term</h2>
          <ul>
            <li>Cultivate sustained growth and expansion by executing comprehensive marketing strategies, fostering strategic alliances, and nurturing a vibrant and engaged community of users and investors.</li>
            <li>Continuously enhance and enrich the platform's features and services, leveraging emerging technologies and industry best practices to cater to the ever-evolving needs of users and market dynamics.</li>
            <li>Collaborate with esteemed financial institutions to facilitate mortgage options for potential buyers, opening up new avenues for investment and expanding the reach and accessibility of the RealtyToke platform.</li>
            <li>Proliferate RealtyToke's global presence, expanding its fractional ownership offerings to diverse geographical regions and international markets, forging partnerships with local entities to ensure seamless integration and compliance with regional regulations.</li>
            <li>Establish a robust and secure ecosystem wherein users can confidently buy, sell, rent, and transact using RealtyToke and other prominent currencies, revolutionizing the real estate industry by providing an innovative and inclusive platform for property investment and asset management.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
