import React, { useState, useEffect } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Modal from "react-modal";

import "./WalletConnectButton.css";

Modal.setAppElement("#root");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
  },
};

const WalletConnectButton = () => {
  const [address, setAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to check if wallet is already connected on component mount
  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum) {
        try {
          const web3 = new Web3(window.ethereum);
          const accounts = await web3.eth.getAccounts();
          if (accounts.length > 0) {
            setAddress(accounts[0]);
            setProvider(window.ethereum);
          }
        } catch (error) {
          console.error("Error checking wallet connection:", error);
        }
      }
    };
    checkWalletConnection();
  }, []);

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.send("eth_requestAccounts");
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        setAddress(accounts[0]);
        setProvider(window.ethereum);
      } catch (error) {
        setErrorMsg("Failed to connect MetaMask");
      }
    } else {
      redirectToMetaMask();
    }
    setModalIsOpen(false);
  };

  const connectWalletConnect = async () => {
    const wcProvider = new WalletConnectProvider({
      rpc: {
        1: "https://mainnet.infura.io/v3/" + process.env.REACT_APP_INFURA_ID,
      },
      bridge: "https://bridge.walletconnect.org",
      qrcode: true,
    });

    try {
      await wcProvider.enable();
      const web3 = new Web3(wcProvider);
      const accounts = await web3.eth.getAccounts();
      setAddress(accounts[0]);
      setProvider(wcProvider);
    } catch (error) {
      console.error("Error connecting with WalletConnect:", error);
      setErrorMsg("Unable to connect to WalletConnect");
    }
    setModalIsOpen(false);
  };

  const redirectToMetaMask = () => {
    window.location.href = "dapp://www.realtytoke.com/";
  };

  const connectWallet = () => {
    setModalIsOpen(true);
  };

  const disconnectWallet = async () => {
    if (provider) {
      try {
        if (provider.close) {
          await provider.close();
        } else if (provider === window.ethereum && window.ethereum._handleDisconnect) {
          window.ethereum._handleDisconnect();
        }
        setAddress(null);
        setProvider(null);
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
        setErrorMsg("Error disconnecting wallet");
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConnectMetaMask = () => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      redirectToMetaMask();
    } else {
      connectMetaMask();
    }
  };

  return (
    <div className="wallet-connect">
      <button
        className={address ? "disconnect-btn" : "connect-btn"}
        onClick={() => (address ? disconnectWallet() : connectWallet())}
      >
        {address ? "Disconnect Wallet" : "Connect Wallet"}
      </button>

      {errorMsg && (
        <div className="error-popup">
          <div className="error-content">
            <p>{errorMsg}</p>
            <button className="close-error-btn" onClick={() => setErrorMsg(null)}>
              Close
            </button>
          </div>
        </div>
      )}

      {modalIsOpen && !address && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-content"
          overlayClassName="ReactModalPortal"
          style={customModalStyles} // Set the custom styles for the modal
        >
          <h2>Select Wallet</h2>
          <div className="wallet-icons">
            <div className="wallet-option" onClick={handleConnectMetaMask}>
              <img
                className="wallet-logo"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
                alt="MetaMask Logo"
              />
              <button className="wallet-btn">
                {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
                  ? "Open in MetaMask"
                  : "MetaMask"}
              </button>
            </div>
            <div className="wallet-option" onClick={connectWalletConnect}>
              <img
                className="wallet-logo"
                src="https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png"
                alt="WalletConnect Logo"
              />
              <button className="wallet-btn">WalletConnect</button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default WalletConnectButton;
