import Web3 from 'web3';
import contractABI from './abi.json'; // Make sure the path is correct

const breakRental = async (contractAddress, tokenId) => {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    console.log(`Contract address: ${contractAddress}`);  // New log
    console.log(`Token ID: ${tokenId}`);                  // New log
    console.log(`Sender address: ${web3.currentProvider.selectedAddress}`); // New log

    await contract.methods.breakRental(tokenId)
      .send({ from: web3.currentProvider.selectedAddress })
      .on('error', (error) => {  // New handler
        console.error('Error sending transaction:', error);
      })
      .on('confirmation', (confirmationNumber, receipt) => {  // New handler
        console.log('Transaction confirmed:', confirmationNumber);
      });

    console.log("Rental broken successfully");
  } catch (error) {
    console.error("Error breaking rental:", error);
  }
};

export default breakRental;
