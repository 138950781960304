import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1 className="not-found-title">Oops!</h1>
      <p className="not-found-text">We couldn't find the page you were looking for.</p>
      <Link to="/" className="not-found-link">Go back to homepage</Link>
    </div>
  );
};

export default NotFound;
