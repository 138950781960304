import Web3 from 'web3';
import contractABI from './abi.json';

const _setRentalAvailability = async (contractAddress, tokenId, isAvailableForRent, rentalPrice) => {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    await contract.methods.setRentalAvailabilityAndPrice(tokenId, isAvailableForRent, rentalPrice).send({
      from: web3.currentProvider.selectedAddress,
    });

    console.log('Rental availability set successfully!');
  } catch (error) {
    console.error('Error setting rental availability:', error);
  }
};

export default _setRentalAvailability;
