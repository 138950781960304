import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import contractABI from './abi.json';
import { utils } from 'web3';
import './NFTList.css';

const NFTList = () => {
  const [nftsForSale, setNFTsForSale] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchNFTsForSale();
  }, []);

  const fetchNFTsForSale = async () => {
    setIsLoading(true);
    try {
      const web3 = new Web3(window.ethereum);
      const contractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8'; 
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const totalSupply = await contract.methods.totalSupply().call();
      
      const nfts = [];
      const tokenIds = [];

      for (let i = 1; i <= totalSupply; i++) {
        const tokenId = i;
        const isForSale = await contract.methods.isAvailableForSale(tokenId).call();

        if (isForSale) {
          const tokenURI = await contract.methods.tokenURI(tokenId).call();
          const metadataResponse = await fetch(tokenURI);
          const metadata = await metadataResponse.json();
          const { name, image, description } = metadata;
          const priceInWei = await contract.methods.getSalePrice(tokenId).call();
          const priceInMatic = web3.utils.fromWei(priceInWei, 'ether'); 
          const formattedPrice = parseFloat(priceInMatic).toFixed(8);

          const traits = metadata.attributes.map((attribute) => ({
            traitType: attribute.trait_type,
            value: attribute.value,
          }));

          nfts.push({
            tokenId,
            name,
            image,
            description,
            price: formattedPrice,
            traits,
          });
          tokenIds.push(tokenId);
        }
      }

      setNFTsForSale(nfts);
    } catch (error) {
      console.error('Error fetching NFTs for sale:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuy = async (tokenId, price) => {
    setIsLoading(true);
    try {
      const web3 = new Web3(window.ethereum);
      const contractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8'; 
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      const accounts = await web3.eth.getAccounts();
      const buyer = accounts[0];

      const balance = await web3.eth.getBalance(buyer);

      if (web3.utils.toBN(balance).lt(web3.utils.toBN(utils.toWei(price.toString(), 'ether')))) {
        alert('Not enough Matic tokens. Please acquire more.');
        return;
      }

      await contract.methods.buy(tokenId).send({ from: buyer, value: utils.toWei(price.toString(), 'ether') });

      fetchNFTsForSale();
    } catch (error) {
      console.error('Error buying NFT:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1>Available NFTs for Sale</h1>
      <div className="nft-list-container">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          nftsForSale.map((nft, index) => (
            <div className="nft-card" key={nft.tokenId}>
              <h2 className="nft-title">{nft.name}</h2>
              <h2 className="nft-title">{nft.tokenId}</h2>
              <p className="nft-owner">Owner: {nft.owner}</p>
              <img className="nft-image" src={nft.image} alt={nft.name} />
              <p className="nft-description">{nft.description}</p>
              <p className="nft-price">Price: {nft.price} MATIC</p>
              {nft.traits.map((trait, index) => (
                <p className="nft-trait" key={index}>{`${trait.traitType}: ${trait.value}`}</p>
              ))}
              <button className="buy-button" onClick={() => handleBuy(nft.tokenId, nft.price)}>Buy</button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NFTList;
