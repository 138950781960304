import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Footer';
import Home from './Home';
import Roadmap from '../src/components/pages/Roadmap';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import NFTDetails from './NFTDetails';
import BuyNFTs from './NFTList';
import RentNFT from './RentList';
import Header from './Header';
import KYCForm from './components/KYCform.js';
import CompliancePage from './components/CompliancePage';
import KYCList from './components/KYCList';
import Terms from './components/pages/terms';
import TokenomicsPage from './components/pages/TokenomicsPage'; // Import TokenomicsPage component
import Mint from './components/mint/mint'; // Import Mint component
import 'bootstrap/dist/css/bootstrap.min.css';
import Mintnft from './mintnft/mint';

import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app">
      <Header />
        <div className="app-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/nft/:contractAddress/:tokenId" element={<NFTDetails />} />
            <Route path="/buy-nfts" element={<BuyNFTs />} />
            <Route path="/rent-nfts" element={<RentNFT />} />
            <Route path="/kyc-form" element={<KYCForm />} />
            <Route path="/compliance" element={<CompliancePage />} />
            <Route path="/kyc-list" element={<KYCList />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/tokenomics" element={<TokenomicsPage />} /> {/* Add the route for TokenomicsPage */}
            <Route path="/mint" element={<Mint />} /> {/* Add the route for Mint component */}
            <Route path="/mintnft" element={<Mintnft />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer className="footer" />
      </div>
    </Router>
  );
};

export default App;
