import React, { useState, useEffect } from "react";
import Web3 from "web3";
import contractAbi from "./abi.json"; // Import the ABI file
import "./mint.css";

const contractAddress = "0x955036f3Ceda956c497a9E5BBfAd80c7CFc0135A"; // Contract address

const Mint = () => {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [amountToMint, setAmountToMint] = useState(0);
  const [price, setPrice] = useState(0);
  const [contractPrice, setContractPrice] = useState(0);
  const [liquidityWalletBalance, setLiquidityWalletBalance] = useState(0);
  const [liquidityPercentageChange, setLiquidityPercentageChange] = useState(0);

  useEffect(() => {
    const connectWeb3 = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("Web3 provider not found");
      }
    };

    connectWeb3();
  }, []);

  useEffect(() => {
    if (web3) {
      const contractInstance = new web3.eth.Contract(contractAbi, contractAddress);
      setContract(contractInstance);

      const fetchContractPrice = async () => {
        try {
          const currentPrice = await contractInstance.methods.getCurrentPrice().call();
          const priceInETH = web3.utils.fromWei(currentPrice, 'ether');
          setContractPrice(priceInETH);
          console.log("Fetched Contract Price:", priceInETH);
        } catch (error) {
          console.error(error);
        }
      };

      fetchContractPrice();
    }
  }, [web3]);

  useEffect(() => {
    if (web3) {
      web3.eth.getAccounts().then((accounts) => {
        setConnectedAddress(accounts[0]);
      });
    }
  }, [web3]);

  useEffect(() => {
    if (contract && connectedAddress) {
      contract.methods.balanceOf(connectedAddress).call().then((result) => {
        setBalance(result);
      });
    }
  }, [contract, connectedAddress]);

  useEffect(() => {
    const fetchPrice = async () => {
      if (contract) {
        try {
          const currentPrice = await contract.methods.getCurrentPrice().call();
          const priceInETH = web3.utils.fromWei(currentPrice, 'ether');
          setPrice(priceInETH);
          console.log("Fetched Current Price:", priceInETH);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchPrice();
  }, [contract]);

  useEffect(() => {
    const fetchLiquidityWalletBalance = async () => {
      if (contract) {
        try {
          const liquidityBalance = await contract.methods.getLiquidityPoolBalance().call();
          const liquidityBalanceInETH = web3.utils.fromWei(liquidityBalance, 'ether');
          setLiquidityWalletBalance(liquidityBalanceInETH);
          console.log("Fetched Liquidity Wallet Balance:", liquidityBalanceInETH);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchLiquidityWalletBalance();
  }, [contract]);

  useEffect(() => {
    const fetchLiquidityPercentageChange = async () => {
      if (contract) {
        try {
          const percentageChange = await contract.methods.getLiquidityChangePercentage().call();
          setLiquidityPercentageChange(percentageChange * 1e-2);
          console.log("Fetched Liquidity Percentage Change:", percentageChange * 1e-2);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchLiquidityPercentageChange();
  }, [contract]);

  const handleAmountChange = (event) => {
    setAmountToMint(event.target.value);
  };

  const handleMint = async () => {
    if (contract && connectedAddress && amountToMint > 0 && contractPrice > 0) {
      try {
        if (amountToMint <= 0) {
          alert("Please enter a valid amount to mint.");
          return;
        }

        const mintValueInWei = web3.utils.toWei((amountToMint * contractPrice).toString(), "ether");

        console.log("Minting Inputs:");
        console.log("Connected Address:", connectedAddress);
        console.log("Amount to Mint:", amountToMint);
        console.log("Contract Price:", contractPrice);
        console.log("MintValue (in Wei):", mintValueInWei);

        await contract.methods.mint(connectedAddress, amountToMint)
          .send({ from: connectedAddress, value: mintValueInWei });

        setAmountToMint(0);

      } catch (error) {
        if (error.code === 4100) {
          alert("Please allow the application to connect with MetaMask and make sure you are on the correct network.");
        } else {
          console.error(error);
        }
      }
    } else {
      alert("Please make sure that you are connected and the contract is initialized.");
    }
  };

  return (
    <div className="container">
      <h1 className="title">Mint Tokens</h1>
      <div className="info">
        <h3 className="subtitle">Connected Address: {connectedAddress}</h3>
        <h3 className="subtitle">Balance: {balance} Tokens</h3>
        <h3 className="subtitle">Price: {price} ETH per Token</h3>
        <h3 className="subtitle">Contract Price: {contractPrice} ETH per Token</h3>
        <h3 className="subtitle">Liquidity Wallet Balance: {liquidityWalletBalance} ETH</h3>
        <h3 className="subtitle">Liquidity Percentage Change: {liquidityPercentageChange}%</h3>
      </div>
      <div className="mint-section">
        <h3 className="mint-title">Amount to Mint:</h3>
        <input type="number" value={amountToMint} onChange={handleAmountChange} className="mint-input" />
        <button onClick={handleMint} disabled={contractPrice <= 0} className="mint-button">
          Mint
        </button>
      </div>
    </div>
  );
};

export default Mint;
