import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { pinJSONToIPFS, pinFileToIPFS } from './pinataUtils';
import 'react-datepicker/dist/react-datepicker.css';
import Web3 from 'web3';
import abi from './abi.json';

const KYCForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [jsonHash, setJsonHash] = useState('');
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [residentialAddress, setResidentialAddress] = useState('');
  const [idDocument, setIdDocument] = useState('');
  const [idDocumentNumber, setIdDocumentNumber] = useState('');
  const [idDocumentImage, setIdDocumentImage] = useState(null);
  const [idDocumentImageUrl, setIdDocumentImageUrl] = useState('');
  const [proofOfResidence, setProofOfResidence] = useState('');
  const [proofOfResidenceImage, setProofOfResidenceImage] = useState(null);
  const [proofOfResidenceImageUrl, setProofOfResidenceImageUrl] = useState('');
  const [nationality, setNationality] = useState('');
  const [contactInformation, setContactInformation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [sourceOfFunds, setSourceOfFunds] = useState('');
  const [purposeOfRelationship, setPurposeOfRelationship] = useState('');
  const [trsHash, setTrsHash] = useState('');
  const [err, setErr] = useState('');
  const [pinningImage, setPinningImage] = useState(false);
  const [pinningJSON, setPinningJSON] = useState(false);
  const [signerAddress, setSignerAddress] = useState('');

  const handleImageUpload = async (e, setImageUrl) => {
    const file = e.target.files[0];

    if (file) {
      try {
        setPinningImage(true);
        const imageHash = await pinFileToIPFS(file);
        setImageUrl(`https://gateway.pinata.cloud/ipfs/${imageHash}`);
        setPinningImage(false);
      } catch (error) {
        setPinningImage(false);
        setErr('Error pinning image');
        console.log('Error pinning image:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const traits = [
      { trait_type: 'Full Name', value: fullName },
      { trait_type: 'Date of Birth', value: dateOfBirth.toISOString() },
      { trait_type: 'Residential Address', value: residentialAddress },
      { trait_type: 'ID Document', value: idDocument },
      { trait_type: 'ID Document Number', value: idDocumentNumber },
      { trait_type: 'ID Document Image URL', value: idDocumentImageUrl },
      { trait_type: 'Proof of Residence', value: proofOfResidence },
      { trait_type: 'Proof of Residence Image URL', value: proofOfResidenceImageUrl },
      { trait_type: 'Nationality', value: nationality },
      { trait_type: 'Contact Information', value: contactInformation },
      { trait_type: 'Occupation', value: occupation },
      { trait_type: 'Source of Funds', value: sourceOfFunds },
      { trait_type: 'Purpose of Relationship', value: purposeOfRelationship },
    ];

    const combinedData = {
      name,
      email,
      traits,
      imgUri: 'https://www.realtytoke.com/realty%20toke.png',
    };

    try {
      setPinningJSON(true);
      const jsonHash = await pinJSONToIPFS(combinedData);
      setJsonHash(`https://gateway.pinata.cloud/ipfs/${jsonHash}`);
      setPinningJSON(false);
    } catch (error) {
      setPinningJSON(false);
      setErr('Error pinning JSON');
      console.log('Error pinning JSON:', error);
    }
  };

  useEffect(() => {
    const getCurrentUserAddress = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.enable();
          const web3 = new Web3(window.ethereum);
          const accounts = await web3.eth.getAccounts();
          if (accounts.length > 0) {
            setSignerAddress(accounts[0]);
          } else {
            setSignerAddress('');
          }
        } catch (error) {
          console.log('Error getting signer address:', error);
        }
      }
    };

    getCurrentUserAddress();
  }, []);

  const handleMint = async () => {
    if (!signerAddress) {
      console.log('Signer address not available');
      return;
    }

    try {
      const web3 = new Web3(window.ethereum);
      const contractAddress = '0xf7a347577Ec5fC592Fd5Cab81224bed66a7c8697';
      const contract = new web3.eth.Contract(abi, contractAddress);

      const traits = [
        { trait_type: 'Full Name', value: fullName },
        { trait_type: 'Date of Birth', value: dateOfBirth.toISOString() },
        { trait_type: 'Residential Address', value: residentialAddress },
        { trait_type: 'ID Document', value: idDocument },
        { trait_type: 'ID Document Number', value: idDocumentNumber },
        { trait_type: 'ID Document Image URL', value: idDocumentImageUrl },
        { trait_type: 'Proof of Residence', value: proofOfResidence },
        { trait_type: 'Proof of Residence Image URL', value: proofOfResidenceImageUrl },
        { trait_type: 'Nationality', value: nationality },
        { trait_type: 'Contact Information', value: contactInformation },
        { trait_type: 'Occupation', value: occupation },
        { trait_type: 'Source of Funds', value: sourceOfFunds },
        { trait_type: 'Purpose of Relationship', value: purposeOfRelationship },
      ];

      const combinedData = {
        name,
        email,
        traits,
        imgUri: 'https://www.realtytoke.com/realty%20toke.png',
      };

      const jsonHash = await pinJSONToIPFS(combinedData);

      const txn = await contract.methods.mintKYC(
        fullName,
        email,
        idDocument,
        idDocumentNumber,
        idDocumentImageUrl,
        jsonHash
      ).send({ from: signerAddress });

      const txnHash = txn.transactionHash;

      setTrsHash(txnHash);
      console.log('Minted NFT with Transaction Hash:', txnHash);
    } catch (error) {
      setErr('Error minting NFT');
      console.log('Error minting NFT:', error);
    }
  };

  return (
    <div>
      <h2>KYC Form</h2>
      {signerAddress ? (
        <p>Signer Address: {signerAddress}</p>
      ) : (
        <p>Signer Address not available</p>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Additional Fields */}
        <div>
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="dateOfBirth">Date of Birth:</label>
          <DatePicker
            selected={dateOfBirth}
            onChange={setDateOfBirth}
            dateFormat="yyyy-MM-dd"
            required
          />
        </div>
        <div>
          <label htmlFor="residentialAddress">Residential Address:</label>
          <input
            type="text"
            id="residentialAddress"
            value={residentialAddress}
            onChange={(e) => setResidentialAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="idDocument">ID Document:</label>
          <input
            type="text"
            id="idDocument"
            value={idDocument}
            onChange={(e) => setIdDocument(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="idDocumentNumber">ID Document Number:</label>
          <input
            type="text"
            id="idDocumentNumber"
            value={idDocumentNumber}
            onChange={(e) => setIdDocumentNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="image">ID Document Image:</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, setIdDocumentImageUrl)}
            required
          />
        </div>
        <div>
          <label htmlFor="proofOfResidence">Proof of Residence:</label>
          <input
            type="text"
            id="proofOfResidence"
            value={proofOfResidence}
            onChange={(e) => setProofOfResidence(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="proofOfResidenceImage">Proof of Residence Image:</label>
          <input
            type="file"
            id="proofOfResidenceImage"
            onChange={(e) => handleImageUpload(e, setProofOfResidenceImageUrl)}
            required
          />
        </div>
        <div>
          <label htmlFor="nationality">Nationality:</label>
          <input
            type="text"
            id="nationality"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="contactInformation">Contact Information:</label>
          <input
            type="text"
            id="contactInformation"
            value={contactInformation}
            onChange={(e) => setContactInformation(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="occupation">Occupation:</label>
          <input
            type="text"
            id="occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="sourceOfFunds">Source of Funds:</label>
          <input
            type="text"
            id="sourceOfFunds"
            value={sourceOfFunds}
            onChange={(e) => setSourceOfFunds(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="purposeOfRelationship">Purpose of Relationship:</label>
          <input
            type="text"
            id="purposeOfRelationship"
            value={purposeOfRelationship}
            onChange={(e) => setPurposeOfRelationship(e.target.value)}
            required
          />
        </div>

        <button type="submit">Submit</button>
      </form>
      {pinningImage && <p>Pinning ID Document image to Pinata...</p>}
      {pinningJSON && <p>Pinning JSON file to Pinata...</p>}
      {jsonHash && <p>JSON File Hash: {jsonHash}</p>}
      {err && <p>Error: {err}</p>}
      <button onClick={handleMint}>Mint NFT</button>
      {trsHash && <p>Transaction Hash: {trsHash}</p>}
    </div>
  );
};

export default KYCForm;
