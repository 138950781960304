import React from 'react';
import './terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
     <h1 className="terms-title">Terms and Conditions</h1>
     <p className="terms-content">
        Please take the time to carefully read and understand the following terms and conditions of use ("Site Terms").
        These Site Terms govern your use of the RealtyToke website, platform, or mobile application (referred to as the "Sites"),
        which is owned and operated by RealtyToke. It is important to note that these Site Terms also include important disclosures
        and regulatory information associated with the services available on the Sites. Your legal rights are affected by these terms,
        so please read them carefully.
      </p>
      <p className="terms-content">
        By accessing or using the Sites, you confirm that:
        <ul>
          <li>You have read, understood, and agree to be bound by these Site Terms.</li>
          <li>You are of legal age to enter into a binding contract with RealtyToke.</li>
          <li>
            You have the authority to personally enter into these terms of use or on behalf of the entity you represent as the
            registered user and bind that entity to the Site Terms.
          </li>
        </ul>
      </p>
      <p className="terms-content">
        The term "you" refers to the individual or legal entity, as applicable, identified as the registered user when you provide
        your registration information on the Sites. By accessing and using the Sites, you agree to comply with these Site Terms and
        all terms incorporated by reference. Your access to and use of the Sites indicate your acceptance of and agreement to abide by
        each of the Site Terms outlined below. If you do not agree to all of these Site Terms, please refrain from using our website,
        platform, or mobile application.
      </p>
      <p className="terms-content">
        These Site Terms specifically apply to your access and use of the RealtyToke website, platform, or mobile application, which
        can be found at <a href="https://www.realtytoke.com/">https://www.realtytoke.com/</a>. They govern your interaction with our
        online services and do not modify the terms or conditions of any other agreement you may have with RealtyToke. If you are using
        the Sites on behalf of an entity, you represent and warrant that you are authorized to accept these Site Terms on behalf of
        that entity, and the entity agrees to be responsible to RealtyToke for any violations of these Site Terms.
      </p>
      <p className="terms-content">
        RealtyToke reserves the right to change or modify any of these Site Terms, as well as any policy or guideline of the Sites, at
        its sole discretion and at any time. In the event of changes to these Site Terms, we will provide notice of such changes by
        posting a notice on the Sites or updating the relevant information. By continuing to use our Sites after the posting of changes
        or modifications, you confirm your acceptance of such changes or modifications. Therefore, we recommend that you review the Site
        Terms and relevant policies regularly whenever you access the Sites, to ensure your understanding of the applicable terms and
        conditions. If you do not agree to the amended terms, you must cease using the Sites.
      </p>
      <h2 className="terms-title">1. Services; Securities Offerings</h2>
      <p className="terms-content">
        Our Sites provide information about potential investments in real estate properties through RealtyToke. These investments
        involve purchasing tokens representing ownership interests in specific properties. Each investment opportunity has its own terms,
        rules, and risks, and it is your responsibility to carefully review all relevant information and make an informed decision.
      </p>
      <p className="terms-content">
        RealtyToke allows you to designate investment opportunities in which you would like to participate. However, participation in
        an investment offering does not guarantee that you will be permitted to participate. Our decisions to permit your participation
        are subject to various factors, including legal requirements, consent from relevant parties, execution of necessary
        documentation, and our discretion. We cannot guarantee the occurrence of any identified transaction or your eligibility to
        participate. If you are offered the opportunity to participate, additional documentation may be required. RealtyToke's management
        decisions are final in all matters related to the Sites, services, investments, and other activities.
      </p>
      <p className="terms-content">
        The investment securities offered through the Sites are not registered under the Securities Act of 1933, as amended, or other
        applicable securities laws. They are offered in reliance on applicable exemptions from registration requirements. These securities
        are not publicly traded, and there is no guarantee of liquidity. The U.S. Securities and Exchange Commission or any state securities
        commission or other regulatory authority has not approved or endorsed any securities offering on the Sites.
      </p>
      <p className="terms-content">
        Investing in real estate securities involves significant risks, and you should only invest if you can afford to lose your entire
        investment. There is no guarantee of achieving the business objectives of RealtyToke, and there may not be a secondary market for
        the tokens representing ownership interests. Investing in private placements requires a high risk tolerance, consideration of low
        liquidity, and long-term commitments. The tokens being sold are not FDIC insured, and there is no bank or other guarantee.
      </p>
      <h2 className="terms-title">2. Legality</h2>
      <p className="terms-content">
        Your use of the Sites and participation in transactions should comply with all applicable laws and regulations. You are
        responsible for verifying the legality of your activities on the Sites and ensuring compliance with all applicable laws,
        regulations, orders, and court decisions in your jurisdiction. If you have any questions regarding legality, it is advisable to
        consult with your legal counsel. By accessing, registering for, or using the Sites, you assume all risks and responsibilities
        associated with complying with the applicable rules and regulations.
      </p>
      <h2 className="terms-title">3. No Financial Advice</h2>
      <p className="terms-content">
        RealtyToke is not a registered broker-dealer, funding portal, or investment advisor, and the information provided on the Sites
        does not constitute a recommendation to buy or sell any securities or assets. The information presented on the Sites is not
        tailored to your specific investment requirements or financial situation. The Sites do not provide financial advice or recommend
        specific investments. It is advisable to consult with qualified professionals before making any investment or financial
        decisions. Any information available through the Sites is not offered as financial advice and should not be relied upon for
        making financial decisions.
      </p>
      <h2 className="terms-title">4. Registration Data; Account Security; Privacy Policy</h2>
      <p className="terms-content">
        To use the Sites and access the Services, you must create an account on the RealtyToke website and register as a user. During
        the registration process, you will be required to provide your real name, address, phone number, email address, and other
        necessary information. The right to use the Sites and the Services is personal to you or the business you represent and is not
        transferable to any other person. By using the Sites and Services, you agree to comply with these Site Terms and our Privacy
        Policy.
      </p>
      <p className="terms-content">
        By creating an account, you agree to provide accurate, current, and complete registration information. You are responsible for
        maintaining the security of your password and account identification. It is your responsibility to keep the registration
        information and other details provided to RealtyToke accurate and up to date. You acknowledge the risks associated with
        unauthorized access to your account and agree to accept all such risks. Sharing the Services or providing access to another
        person through your account is a breach of these Site Terms and may constitute fraud or theft, for which RealtyToke reserves all
        rights and remedies. You are not permitted to authorize any individual or entity other than your authorized agents, if any, to
        use the Sites or Services through your account.
      </p>
      <p className="terms-content">
        While providing personally identifying information is not mandatory under the law, failure to provide the required registration
        data or other personally identifying information may limit your ability to use certain features of the Sites or the Services.
        RealtyToke will handle your personally identifying information as described in its Privacy Policy. By creating an account, you
        represent and warrant that all registration data you provide is truthful and accurate, and you agree not to use the account of
        another user.
      </p>
      <h2 className="terms-title">5. Copyright and Limited License</h2>
      <p className="terms-content">
        All content and materials on the RealtyToke Sites, including the website design, text, graphics, pictures, information, data,
        software, sound files, and other files (collectively referred to as "Site Materials"), are the proprietary property of RealtyToke
        or its licensors or users. These materials are protected by U.S. and international copyright laws. You are granted a limited,
        non-sublicensable license to access and use the Sites and to electronically copy and print portions of the Site Materials for
        your personal, non-commercial, and informational use only. This license is subject to these Site Terms and does not include the
        right to:
      </p>
      <ul>
        <li>Resell or commercially use the Sites or the Site Materials.</li>
        <li>Distribute, publicly perform, or display any Site Materials.</li>
        <li>Modify or create derivative works of the Sites or the Site Materials.</li>
        <li>Use any data mining, robots, or similar data gathering or extraction methods.</li>
        <li>Download any portion of the Sites or the Site Materials, except for page caching as permitted.</li>
        <li>Use the Sites or the Site Materials for any purpose other than its intended purpose.</li>
      </ul>
      <p className="terms-content">
        Any unauthorized use of the Sites or the Site Materials is strictly prohibited and may violate applicable laws, including
        copyright and trademark laws, as well as communications regulations and statutes. Unless explicitly stated in these Site Terms,
        no license is conferred to intellectual property rights. RealtyToke retains the right to revoke this license at any time.
      </p>
      <h2 className="terms-title">6. Repeat Infringer Policy</h2>
      <p className="terms-content">
        RealtyToke has adopted a policy to terminate access to the Sites, at its sole discretion, for users who are deemed repeat
        infringers, in accordance with the Digital Millennium Copyright Act (DMCA) and other applicable laws. RealtyToke may also, at
        its sole discretion, limit access to the Sites or terminate the accounts of any users who infringe intellectual property rights
        of others, regardless of repeat infringement.
      </p>
      <h2 className="terms-title">7. Copyright Complaints</h2>
      <p className="terms-content">
        If you believe that any content on the Sites infringes upon your copyright, you may file a notification of such infringement
        with RealtyToke's Designated Agent. Please refer to the provided contact information and follow the requirements of a proper
        notification, as outlined in 17 U.S.C. 512(c)(3). RealtyToke reserves the right to remove or disable access to any material or
        activity claimed to be infringing upon receiving a proper notification. Please note that knowingly misrepresenting in your
        notification that material or activity is infringing may result in liability for damages, including costs and attorneys' fees.
      </p>
      <p className="terms-content">
        Designated Agent:
        <br />
        Mike Daanen
        <br />
        RealtyToke
        <br />
        Email: mike@realtytoke.com
      </p>
      <h2 className="terms-title">8. Trademarks</h2>
      <p className="terms-content">
        RealtyToke, the RealtyToke logo, and any other product or service names or slogans featured on our Sites are trademarks of
        RealtyToke and its suppliers or licensors. These trademarks may not be copied, imitated, or used, in whole or in part, without
        the prior written permission of RealtyToke or the applicable trademark holder. You are not permitted to use any metatags or
        hidden text containing the name "RealtyToke" or any other trademark, name, or product/service name of RealtyToke without our
        prior written permission. Furthermore, the overall look and feel of our Sites, including page headers, custom graphics, button
        icons, and scripts, constitute the service mark, trademark, and/or trade dress of RealtyToke and may not be copied, imitated,
        or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names,
        and company names or logos mentioned on our Sites are the property of their respective owners. Any reference to products,
        services, processes, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not imply
        endorsement, sponsorship, or recommendation by RealtyToke.
      </p>
      <h2 className="terms-title"> 9. Hyperlinks</h2>
      <p className="terms-content">
        You are granted a limited, non-exclusive right to create a text hyperlink to our Sites for noncommercial purposes, provided that
        such link does not depict RealtyToke or any of its products and services in a false, misleading, derogatory, or defamatory
        manner. Additionally, the linking site must not contain any adult or illegal material or any content that is offensive,
        harassing, or objectionable. This limited right may be revoked at any time. You are not authorized to use the RealtyToke logo
        or any other proprietary graphics of RealtyToke to link to our Sites without our express written permission. Furthermore, you
        are not allowed to use framing techniques or enclose any RealtyToke trademark, logo, or proprietary information, including
        images found on our Sites, the content of any text, or the layout/design of any page or form, without the express written
        consent of RealtyToke. Unless specifically mentioned above, no right or license is granted to you, whether implicitly or
        explicitly, under any patent, trademark, copyright, or proprietary right of RealtyToke or any third party.
      </p>
      <p className="terms-content">
        RealtyToke makes no claim or representation regarding, and assumes no responsibility for, the quality, content, nature, or
        reliability of third-party websites accessible through hyperlinks on our Sites or websites linking to our Sites. Such websites
        are not under the control of RealtyToke, and RealtyToke is not responsible for the content of any linked site or any link
        contained in a linked site, or for any changes or updates to such sites. The inclusion of any hyperlink on our Sites does not
        imply affiliation, endorsement, or adoption by RealtyToke of the linked site or any information contained therein. When you
        leave our Sites, please be aware that our terms and policies no longer govern. We recommend reviewing the applicable terms,
        policies, and practices, including privacy and data gathering practices, of any site you visit from our Sites.
      </p>
      <h2 className="terms-title"> 10. Third-Party Content</h2>
      <p className="terms-content">
        RealtyToke may provide third-party content on our Sites and may include links to web pages and content of third parties
        (referred to as "Third-Party Content") as a service to those interested in such information. RealtyToke does not control,
        endorse, or adopt any Third-Party Content and makes no representations or warranties of any kind regarding the Third-Party
        Content, including its accuracy or completeness. You acknowledge and agree that RealtyToke is not responsible or liable in any
        manner for any Third-Party Content and assumes no responsibility to update or review any Third-Party Content. Any use of the
        Third-Party Content is at your own risk.
      </p>
      <h2 className="terms-title"> 11. Third-Party Products and Services</h2>
      <p className="terms-content">
        RealtyToke may provide information about or links to third-party products or services on our Sites. Your interactions,
        transactions, or correspondence with such third parties, as well as any terms, conditions, warranties, or representations
        associated with such dealings or promotions, are solely between you and the third party. RealtyToke is not responsible or
        liable for any loss or damage incurred as a result of such dealings or promotions or due to the presence of non-RealtyToke
        advertisers or third-party information on our Sites.
      </p>
      <h2 className="terms-title">12. Feedback</h2>
      <p className="terms-content">
        We appreciate any questions, comments, suggestions, feedback, ideas, plans, notes, drawings, original or creative materials, or
        other information or materials regarding our Sites, RealtyToke, or our general products or services (collectively referred to
        as "Feedback") that you provide via email, submissions to RealtyToke, or postings on our Sites. Please note that any Feedback
        you provide becomes the property of RealtyToke, and by providing such Feedback, you assign all exclusive rights, including
        intellectual property rights, to RealtyToke. RealtyToke is entitled to use and disseminate the Feedback for any purpose,
        commercial or otherwise, without acknowledgment or compensation to you.
      </p>
      <h2 className="terms-title">13. User Content and Interactive Services or Areas</h2>
      <p className="terms-content">
        Our Sites may include interactive areas or services (referred to as "Interactive Areas"), such as forums, chat rooms, message
        boards, online hosting or storage services, or other areas or services where you or other users can create, post, or store any
        content, messages, materials, data, information, text, music, sound, photos, video, graphics, applications, code, or other items
        or materials on our Sites (referred to as "User Content"). You are solely responsible for your use of the Interactive Areas and
        use them at your own risk. By using any Interactive Areas, you agree not to upload, post, transmit, store, distribute, create,
        or otherwise publish through our Sites any of the following:
      </p>
      <ul>
        <li>
          User Content that constitutes, encourages, or provides instructions for a criminal offense, violates the rights of any party,
          or creates liability or violates any local, state, national, or international law, including regulations of the U.S. Securities
          and Exchange Commission or rules of securities exchanges such as the New York Stock Exchange, the American Stock Exchange, or
          the NASDAQ.
        </li>
        <li>
          User Content that is unlawful, libelous, defamatory, pornographic, obscene, lewd, indecent, suggestive, harassing, threatening,
          invasive of privacy or publicity rights, abusive, fraudulent, inflammatory, or otherwise objectionable.
        </li>
        <li>
          User Content that impersonates any person or entity or misrepresents your affiliation with a person or entity.
        </li>
        <li>
          User Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of
          any party. By posting any User Content, you represent and warrant that you have the lawful right to distribute and reproduce
          such User Content.
        </li>
        <li>Unsolicited promotions, political campaigns, advertising, or solicitations.</li>
        <li>Viruses, corrupted data, or other harmful, disruptive, or destructive files.</li>
        <li>
          Private information of any third party, including addresses, phone numbers, email addresses, Social Security numbers, and
          credit card numbers.
        </li>
        <li>
          User Content that, in the sole judgment of RealtyToke, is objectionable or restricts or inhibits any other person from using
          or enjoying the Interactive Areas or our Sites or may expose RealtyToke or its users to any harm or liability of any type.
        </li>
      </ul>
      <p className="terms-content">
        Additionally, you agree that while using our Sites or Services, you will not:
      </p>
      <ul>
        <li>Impersonate or post on behalf of any person or entity or otherwise misrepresent your affiliation with a person or entity.</li>
        <li>
          Use the Sites or Services in a manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully
          enjoying the Sites or that could damage, disable, overburden, or impair the functioning of the Sites in any manner.
        </li>
        <li>Stalk, intimidate, threaten, or otherwise harass or cause discomfort to other users.</li>
        <li>Cheat or utilize unauthorized exploits in connection with the Services.</li>
        <li>Send any unsolicited commercial messages.</li>
        <li>
          Use the Sites or Services for any illegal or unauthorized purpose or engage in, encourage, or promote any illegal activity or
          activity that violates these Terms of Use.
        </li>
        <li>
          Circumvent or attempt to circumvent any filtering, security measures, or other features that RealtyToke may implement from
          time to time to protect the Sites, its users, or third parties.
        </li>
      </ul>
      <p className="terms-content">
        RealtyToke reserves the right, at its sole discretion, to remove, screen, or edit any User Content posted or stored on our
        Sites without notice. You are solely responsible for creating backup copies of and replacing any User Content you post or store
        on our Sites at your own cost and expense. RealtyToke is not liable for any User Content posted, stored, or uploaded by you or
        any third party. Your use of Interactive Areas is at your own risk.
      </p>
      <h2 className="terms-title"> 14. Indemnification</h2>
      <p className="terms-content">
        You agree to defend, indemnify, and hold harmless RealtyToke, its affiliates, independent contractors, service providers, and
        consultants, as well as their respective directors, employees, and agents, from and against any claims, damages, costs,
        liabilities, and expenses (including reasonable attorneys' fees) arising out of or related to any User Content you post, store,
        or transmit on or through our Sites, or your use of the Interactive Areas. This includes any actual or threatened suit, demand,
        or claim made against RealtyToke and/or its affiliates, independent contractors, service providers, employees, directors, or
        consultants arising from the User Content, your conduct, your violation of these Site Terms, or your violation of the rights of
        any third party.
      </p>
      <h2 className="terms-title">15. Disclaimer</h2>
      <p className="terms-content">
        Unless expressly provided otherwise in writing by RealtyToke, our Sites, the content contained therein, and the services
        provided on or in connection with them (referred to as "Services") are provided on an "as is" basis without any warranties of
        any kind, either express or implied. RealtyToke disclaims all other warranties, express or implied, including, without
        limitation, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement with respect to
        the Sites and the Services, including the information, content, and materials contained therein. RealtyToke does not warrant
        that the materials in the Sites or the Services are accurate, complete, reliable, current, or error-free. RealtyToke does not
        warrant that the Sites or its servers are free from viruses or other harmful components.
      </p>
      <p className="terms-content">
        RealtyToke is not responsible for typographical errors or omissions regarding pricing, text, or photography. While RealtyToke
        endeavors to ensure the safety of your access and use of the Sites and Services, we cannot guarantee that the Sites or its
        servers are free from viruses or other harmful components. Therefore, it is recommended to use industry-recognized software to
        detect and disinfect viruses from any downloads.
      </p>
      <h2 className="terms-title"> 16. Limitation of Liability</h2>
      <p className="terms-content">
        In no event shall RealtyToke, its directors, members, employees, or agents be liable for any direct, special, indirect, or
        consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits, or loss of
        data, arising out of or in any way connected with the use of or inability to use the Sites, the Services, the content, or the
        materials contained in or accessed through the Sites. This includes any damages caused by or resulting from reliance on any
        information obtained from RealtyToke, or any mistakes, omissions, interruptions, deletion of files or email, errors, defects,
        viruses, delays in operation or transmission, or any failure of performance, whether or not resulting from acts of God,
        communication failures, theft, destruction, or unauthorized access to RealtyToke's records, programs, or services. In no event
        shall the aggregate liability of RealtyToke, whether in contract, warranty, tort (including negligence, whether active, passive,
        or imputed), product liability, strict liability, or any other theory, arising out of or relating to the use of or inability to
        use the Sites or the Services or these Site Terms exceed any compensation you have paid, if any, to RealtyToke for access to or
        use of the Sites.
      </p>
      <p className="terms-content">
        There are risks associated with using an internet-based blockchain protocol (including any customized ERC-20 standard RealToken
        we may provide), including but not limited to risks related to hardware, software, and internet connections, the risk of
        introducing malicious software, and the risk that third parties may obtain unauthorized access to information stored within
        your Account or the digital assets stored in your Wallet. By accepting these terms, you acknowledge and accept that RealtyToke
        will not be responsible for any communication failures, disruptions, errors, distortions, delays, or third-party fraud you may
        experience due to circumstances beyond our reasonable control.
      </p>
      <p className="terms-content">
        REALTYTOKE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKES NO RESPONSIBILITY FOR ANY DAMAGES ARISING FROM ANY
        DECISIONS OR ACTIONS MADE BY YOU BASED ON REALTYTOKE SITES' CONTENT OR INFORMATION ON REALTYTOKE'S SERIES OFFERINGS OR THROUGH
        THE USE OF CRYPTOCURRENCY OR REALTOKENS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM:
      </p>
      <ul>
        <li>
          (A) USER ERRORS SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, INCORRECT CRYPTOCURRENCY WALLET
          ADDRESSES, BANK WIRE INFORMATION, OR MISTYPED ADDRESSES;
        </li>
        <li>
          (B) SERVER FAILURE OR DATA LOSS;
        </li>
        <li>
          (C) CORRUPTED WALLET FILES;
        </li>
        <li>
          (D) UNAUTHORIZED ACCESS TO SITES OR SERVICES;
        </li>
        <li>
          (E) ANY UNAUTHORIZED THIRD-PARTY ACTIVITIES, INCLUDING VIRUSES, PHISHING, BRUTE FORCING, OR OTHER MEANS OF CYBER ATTACK
          AGAINST THE REALTYTOKE SITES OR ANY ASSOCIATED SERVICES, INCLUDING THE REALTOKENS.
        </li>
      </ul>
      <h2 className="terms-title"> 17. Modifications to the Sites</h2>
      <p className="terms-content">
        RealtyToke reserves the right to change any and all content contained in the Sites, as well as modify, suspend, or discontinue
        the Sites, any Services offered through the Sites, or any features or functionality of the Sites or the Services at any time
        without notice and without obligation or liability to you.
      </p>
      <h2>18. Applicable Law and Venue</h2>
      <p className="terms-content">
        These Site Terms and your use of the Sites shall be governed by and construed in accordance with the laws of the State of
        Florida, applicable to agreements made and entirely performed within the State of Florida, without resorting to its conflict of
        law provisions. You agree that any action at law or in equity arising out of or relating to these Site Terms shall be filed only
        in the state and federal courts located in Miami-Dade County, Florida. By accepting these terms, you irrevocably and
        unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action, or proceeding arising
        out of these Site Terms.
      </p>
      <h2 className="terms-title">19. Termination</h2>
      <p className="terms-content">
        RealtyToke reserves the right, without notice and at its sole discretion, to terminate your license to use the Sites and to
        block or prevent your future access to and use of the Sites.
      </p>
      <h2 className="terms-title"> 20. Severability</h2>
      <p className="terms-content">
        If any provision of these Site Terms is deemed unlawful, void, or unenforceable, that provision shall be deemed severable from
        these Site Terms and shall not affect the validity and enforceability of any remaining provisions.
      </p>
      <p className="terms-content">
        These terms and conditions of use, along with the information provided on the RealtyToke website
        (https://www.realtytoke.com/), govern your use of the RealtyToke platform and services. Please ensure that you review and
        understand these terms before using our platform or engaging in any transactions.
      </p>
    </div>
  );
};

export default Terms;
