import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import WalletConnectButton from './WalletConnectButton';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isWalletInstalled, setWalletInstalled] = useState(false);
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState(null);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const checkWalletStatus = async () => {
      if (typeof window.ethereum !== 'undefined') {
        setWalletInstalled(true);
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setWalletConnected(true);
            setWalletAddress(accounts[0]);
          } else {
            setWalletConnected(false);
            setWalletAddress(null);
          }

          window.ethereum.on('accountsChanged', (newAccounts) => {
            if (newAccounts.length > 0) {
              setWalletConnected(true);
              setWalletAddress(newAccounts[0]);
            } else {
              setWalletConnected(false);
              setWalletAddress(null);
            }
          });
        } catch (error) {
          console.error("Failed to check wallet connection:", error);
        }
      } else {
        setWalletInstalled(false);
        setWalletConnected(false);
        setWalletAddress(null);
      }
      setIsLoading(false);
    };

    checkWalletStatus();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  return (
    <header className={`header-container ${isScrolled ? 'scrolled' : ''}`}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container nav-container">
          <NavLink exact to="/" className="navbar-brand" onClick={() => setMenuOpen(false)}>
            <img src="./realty toke.png" alt="Logo" width="150" height="150" />
          </NavLink>
          <button
            className={`navbar-toggler ${isMenuOpen ? 'collapsed' : ''}`}
            type="button"
            onClick={handleMenuToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto nav-menu">
              <li className="nav-item">
                <NavLink exact to="/" className="nav-link" onClick={() => setMenuOpen(false)}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link" onClick={() => setMenuOpen(false)}>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/buy-nfts" className="nav-link" onClick={() => setMenuOpen(false)}>
                  Buy NFTs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/rent-nfts" className="nav-link" onClick={() => setMenuOpen(false)}>
                  Rent NFTs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact to="/mint" className="nav-link mint-button" onClick={() => setMenuOpen(false)}>
                  Mint RT
                </NavLink>
              </li>
            </ul>
            <div className="wallet-connect-container">
              <WalletConnectButton connected={isWalletConnected} />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
