import React, { useEffect } from 'react';
import './CompliancePage.css';

const CompliancePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="compliance-page">
      <header className="compliance-header">
        <h1 className="compliance-title">Compliance and Regulatory Information</h1>
      </header>
      <main className="compliance-main">
        <section className="compliance-section">
          <h2 className="compliance-section-title">EU Prospectus Regulation Exemption</h2>
          <p className="compliance-section-content">
            Under the EU Prospectus Regulation, we may qualify for exemptions that simplify the regulatory requirements for our offerings. These exemptions include:
            <ul>
              <li>Small Offerings: If the total value of our securities offering in the EU is less than €1 million over a period of 12 months, we are exempt from publishing a prospectus.</li>
              <li>Limited Number of Investors: We offer our securities to fewer than 150 natural or legal persons per EU member state.</li>
              <li>Minimum Investment: Our securities require a minimum investment of €100,000 or more per investor.</li>
            </ul>
            Please note that while these exemptions alleviate the need for a full prospectus, we comply with other relevant regulatory obligations.
          </p>
        </section>
        <section className="compliance-section">
          <h2 className="compliance-section-title">Thailand Small Investor Threshold Exemption</h2>
          <p className="compliance-section-content">
            In Thailand, we comply with the securities regulations set by the Securities and Exchange Commission (SEC) and the Securities and Exchange Act. We qualify for the following exemption:
            <ul>
              <li>Small Offering: Our securities offering falls below the threshold defined by Thai securities laws. Offerings with a total value below this threshold, typically around 20 million Thai Baht (THB), qualify as small offerings. This exemption simplifies the regulatory requirements for smaller businesses and startups.</li>
            </ul>
            By meeting this exemption, we aim to facilitate capital raising while ensuring compliance with Thai securities laws.
          </p>
        </section>
        <section className="compliance-section">
          <h2 className="compliance-section-title">Dubai Exemptions</h2>
          <p className="compliance-section-content">
            In Dubai, our offerings are subject to the securities regulations of the Dubai Financial Services Authority (DFSA) within the Dubai International Financial Centre (DIFC). We rely on the following exemptions:
            <ul>
              <li>Private Placement: Our securities offerings are made to a limited number of sophisticated or professional investors. This exemption enables us to target specific investor groups while complying with DFSA regulations.</li>
              <li>Small Offers Exemption: Our offerings fall below the threshold set by the DFSA, allowing us to qualify for this exemption. It simplifies regulatory requirements for offerings with a total value below the specified threshold.</li>
              <li>Exempt Offers: Certain offers made by us may be exempt from registration and prospectus requirements under specific conditions defined by the DFSA. These exemptions are designed to facilitate offerings to restricted investor groups or for specific types of securities.</li>
            </ul>
            Our compliance with these exemptions ensures that we adhere to the relevant regulations and provide transparency to our investors in Dubai.
          </p>
        </section>
        <section className="compliance-section">
          <h2 className="compliance-section-title">KYC (Know Your Customer) Process</h2>
          <p className="compliance-section-content">
            To ensure regulatory compliance and maintain the integrity of our platform, we have implemented a Know Your Customer (KYC) process. KYC is a standard verification process used in the financial industry to verify the identity of customers and prevent fraud, money laundering, and other illegal activities.
            <br />
            When you participate in our platform, you will be required to complete the KYC process. This process involves providing personal information and supporting documentation to verify your identity. The information you provide will be securely stored and used solely for the purpose of regulatory compliance.
            <br />
            By completing the KYC process, you contribute to a safe and trusted environment for all participants on our platform. We prioritize the security and privacy of your personal information and adhere to all applicable data protection regulations.
          </p>
        </section>
      </main>
      <footer className="compliance-footer">
        <p className="compliance-footer-text">
          For more detailed information, please refer to our <a href="/terms">Terms and Conditions</a> and consult with our legal team if you have any questions or concerns regarding regulatory compliance or the KYC process.
        </p>
      </footer>
    </div>
  );
};

export default CompliancePage;
