import React from 'react';
import { Link } from 'react-router-dom';

const NFTCollection = ({ title, collection, contractAddress, showEarnings }) => (
  <div className="nft-collection">
    <h2 className="collection-heading">{title}</h2>
    <div className="nft-grid">
      {collection.map((nft) => (
        <div className="nft-card" key={nft.tokenId}>
          <img className="nft-image" src={nft.image} alt={nft.name} />
          <div className="nft-details">
            <h3 className="nft-name">{nft.name}</h3>
            {showEarnings && <p className="nft-earnings">Earnings: {nft.earnings} MATIC</p>}
            <p>
              Price of the NFT: {nft.attributes.find((attribute) => attribute.trait_type === 'Price of the NFT').value}
            </p>
            <p>
              Period: {nft.attributes.find((attribute) => attribute.trait_type === 'Period').value}
            </p>
            <Link to={`/nft/${contractAddress}/${nft.tokenId}`}>
              <button className="nft-manage-button">Manage NFT</button>
            </Link>
          </div>
        </div>
      ))}
      {collection.length === 0 && <p>No {title} found.</p>}
    </div>
  </div>
);

export default NFTCollection;
