import React from 'react';
import Web3 from 'web3';
import contractABI from './abi.json';

const RentButton = ({ tokenId, rentalPrice }) => {
  const handleRent = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8'; // Replace with your actual contract address
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      const rentalPriceInWei = web3.utils.toWei(rentalPrice.toString(), 'ether');

      // Request permission to access the user's accounts
      await window.ethereum.enable();

      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];

      if (!currentAccount) {
        console.error('Please connect your wallet');
        return;
      }

      await contract.methods.rent(tokenId).send({
        from: currentAccount,
        value: rentalPriceInWei,
      });

      console.log('NFT rented successfully!');
    } catch (error) {
      console.error('Error renting NFT:', error);
    }
  };

  return (
    <button onClick={handleRent}>Rent NFT</button>
  );
};

export default RentButton;
