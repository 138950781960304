import Web3 from 'web3';
import contractABI from './abi.json';

export const getOwnedNFTCollection = async (contractAddress) => {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    const totalSupply = await contract.methods.totalSupply().call();
    const ownedCollection = [];

    for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
      const owner = await contract.methods.ownerOf(tokenId).call();
      if (owner === address) {
        const tokenURI = await contract.methods.tokenURI(tokenId).call();
        const response = await fetch(tokenURI);
        const metadata = await response.json();

        const traits = metadata.attributes.map((attribute) => ({
          traitType: attribute.trait_type,
          value: attribute.value,
        }));

        const earnings = await contract.methods.getEarnings(tokenId).call();
        const maticEarnings = web3.utils.fromWei(earnings, 'ether');

        const nft = { ...metadata, tokenId, traits, earnings: maticEarnings };
        ownedCollection.push(nft);
      }
    }

    return ownedCollection;
  } catch (error) {
    console.error('Error fetching owned NFT collection:', error);
    return [];
  }
};

export const getRentedNFTCollection = async (contractAddress) => {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    const tokenIds = await contract.methods.getRentedNFTsByRenter(address).call();

    const rentedCollection = [];
    for (const tokenId of tokenIds) {
      const owner = await contract.methods.ownerOf(tokenId).call();
      const tokenURI = await contract.methods.tokenURI(tokenId).call();
      const response = await fetch(tokenURI);
      const metadata = await response.json();

      const traits = metadata.attributes.map((attribute) => ({
        traitType: attribute.trait_type,
        value: attribute.value,
      }));

      const nft = { ...metadata, tokenId, traits, owner };
      rentedCollection.push(nft);
    }

    return rentedCollection;
  } catch (error) {
    console.error('Error fetching rented NFT collection:', error);
    return [];
  }
};

export const getRentedOutNFTCollection = async (contractAddress) => {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    const tokenIds = await contract.methods.getRentedNFTsByOwner(address).call();

    const rentedOutCollection = [];
    for (const tokenId of tokenIds) {
      const tokenURI = await contract.methods.tokenURI(tokenId).call();
      const response = await fetch(tokenURI);
      const metadata = await response.json();

      const traits = metadata.attributes.map((attribute) => ({
        traitType: attribute.trait_type,
        value: attribute.value,
      }));

      const nft = { ...metadata, tokenId, traits };
      rentedOutCollection.push(nft);
    }

    return rentedOutCollection;
  } catch (error) {
    console.error('Error fetching rented out NFT collection:', error);
    return [];
  }
};
