import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import contractABI from './abi.json';
import './RentList.css';
import RentButton from './RentButton';

const RentList = () => {
  const [nftList, setNFTList] = useState([]);

  useEffect(() => {
    fetchNFTs();
  }, []);

  const fetchNFTs = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contractAddress = '0x098a8228dA05A4b497520FFE768feE7382B78bB8';
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const totalSupply = await contract.methods.totalSupply().call();

      const nfts = [];
      const promises = [];
      for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
        promises.push(
          contract.methods.isAvailableForRent(tokenId).call()
            .then((isAvailableForRent) => {
              if (isAvailableForRent) {
                return contract.methods.tokenURI(tokenId).call()
                  .then((tokenURI) => fetch(tokenURI))
                  .then((response) => response.json())
                  .then((metadata) => {
                    return contract.methods.getRentalPrice(tokenId).call()
                      .then((rentalPriceWei) => {
                        const rentalPrice = web3.utils.fromWei(rentalPriceWei.toString(), 'ether');

                        const traits = metadata.attributes.map((attribute) => ({
                          traitType: attribute.trait_type,
                          value: attribute.value,
                        }));

                        nfts.push({
                          tokenId,
                          metadata,
                          rentalPrice,
                          traits,
                        });
                      });
                  });
              }
            })
        );
      }

      await Promise.all(promises);

      setNFTList(nfts);

      // Log the fetched NFTs
      console.log('Fetched NFTs:', nfts);
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
  };

  return (
    <div className="rent-list-container">
      <h1>Available NFTs for Rent:</h1>
      {nftList.map((nft) => (
        <div key={nft.tokenId} className="nft-card">
          <h2 className="nft-title">{nft.metadata.name}</h2>
          <img src={nft.metadata.image} alt={nft.metadata.name} className="nft-image" />
          <p>Token ID: {nft.tokenId}</p>
          <p>Description: {nft.metadata.description}</p>

          {nft.traits.map((trait, index) => {
            const allowedTraits = [
              'Price of the NFT',
              'About The condo',
              'City',
              'Country',
              'Number of Bedrooms',
              'Number of Bathrooms',
              'Square Footage/Area',
              'Contract Details',
              'Contact Information',
              'Period',
            ];

            if (allowedTraits.includes(trait.traitType)) {
              return (
                <p className="nft-trait" key={index}>{`${trait.traitType}: ${trait.value}`}</p>
              );
            }

            return null;
          })}
          <p>Rent Price: {nft.rentalPrice} ETH</p>
          <RentButton tokenId={nft.tokenId} rentalPrice={nft.rentalPrice} />
        </div>
      ))}
    </div>
  );
};

export default RentList;
