import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import facebookIcon from './facebook.png';
import instagramIcon from './instagram.png';
import telegramIcon from './telegram.png';

const Footer = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`footer-container ${isScrolled ? 'scrolled' : ''}`}>
      <div className="footer">
        <nav className="footer-menu">
          <Link to="/compliance" className="nav-link">Compliance</Link>
          <Link to="/roadmap" className="nav-link">Roadmap</Link>
          <Link to="/terms" className="nav-link">Terms</Link>
          <Link to="/tokenomics" className="nav-link">Tokenomics</Link>
        </nav>
        <div className="footer-social">
          <a href="https://www.facebook.com/Realtytoke" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" className="footer-icon" />
          </a>
          <a href="https://www.instagram.com/realtytoke/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="footer-icon" />
          </a>
          <a href="https://t.me/realtytoke" target="_blank" rel="noopener noreferrer">
            <img src={telegramIcon} alt="Telegram" className="footer-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
